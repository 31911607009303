<template>
  <div class="content-wrap">
    <img src="@/assets/img/resources/404.png" alt="找不到图片" />
    <div>抱歉，你访问的页面不存在</div>
    <el-button type="primary" @click="toLogin">返回首页</el-button>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    toLogin() {
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 302px;
    height: 256px;
  }

  div {
    font-size: 18px;
    color: #666666;
    margin: 60px 0 20px 0;
  }
}
</style>